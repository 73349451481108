<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
    <!-- 사이트 및 관제지역관리 --------------------------------------------------------------------------------------------->
    <BCard header-tag="header" footer-tag="footer">
      <div slot="header">
        <BIconBuilding /> <strong> 사이트 설정 </strong>
        <div class="card-header-actions">
          <small class="text-muted">저장소 정보를 관리합니다.</small>
        </div>
      </div>

      <BRow>
        <BCol xl="6">
          <CCard border-color="info">
            <CCardHeader>
              <BIconBoxSeam class="mr-2" /> <strong> 사이트 정보 </strong>
            </CCardHeader>

            <CCardBody>
              <BForm @submit="siteSubmit">
                <CCard accent-color="info">
                  <CCardHeader> 기본 정보 </CCardHeader>
                  <CCardBody>
                    <BFormGroup
                      size="sm"
                      label="사이트 이름"
                      label-for="name"
                      label-cols-md="3"
                      valid-feedback="사용 가능한 사이트 이름 입니다"
                      invalid-feedback="3자 이상 50자 이하"
                      :state="site.name.length > 2"
                    >
                      <BFormInput
                        size="sm"
                        id="name"
                        v-model="site.name"
                        :state="site.name.length > 2"
                        maxLength="50"
                        required
                        trim
                      />
                    </BFormGroup>

                    <BFormGroup
                      size="sm"
                      label="사이트 주소"
                      label-for="address"
                      label-cols-md="3"
                    >
                      <BFormInput
                        size="sm"
                        id="address"
                        v-model="site.address"
                        maxLength="200"
                        trim
                      />
                    </BFormGroup>

                    <BFormGroup
                      size="sm"
                      label="전화번호"
                      label-cols-md="3"
                      valid-feedback="전화번호 또는 휴대폰 번호 형식 입니다"
                      invalid-feedback="전화번호 입력 에) 02-345-6787, 010-1234-5678"
                      label-for="telNo"
                      :state="isValidExp(site.telNo, 'tel') || isValidExp(site.telNo, 'hp')"
                    >
                      <BFormInput
                        size="sm"
                        id="telNo"
                        v-model="site.telNo"
                        maxLength="50"
                        :state="isValidExp(site.telNo, 'tel') || isValidExp(site.telNo, 'hp')"
                        trim
                      />
                    </BFormGroup>

                    <BFormGroup
                      size="sm"
                      label="팩스번호"
                      label-cols-md="3"
                      valid-feedback="전화번호 형식 입니다."
                      invalid-feedback="팩스번호 입력 에) 02-345-6787"
                      label-for="faxNo"
                      :state="isValidExp(site.faxNo, 'tel')"
                    >
                      <BFormInput
                        size="sm"
                        id="faxNo"
                        v-model="site.faxNo"
                        maxLength="50"
                        :state="isValidExp(site.faxNo, 'tel')"
                        trim
                      />
                    </BFormGroup>
                  </CCardBody>
                </CCard>

                <CCard accent-color="danger">
                  <CCardHeader> 사이트 식별정보 </CCardHeader>
                  <CCardBody>
                    <BFormGroup
                      size="sm"
                      label="서비스 식별 코드"
                      label-for="companyId"
                      label-cols-md="3"
                      valid-feedback="사용 가능한 코드입니다"
                      invalid-feedback="숫자 4자 입력 예) 0009"
                      :state="isValidExp(site.companyId, 'num', 4)"
                    >
                      <BFormInput
                        size="sm"
                        id="companyId"
                        v-model="site.companyId"
                        @input="v => (site.companyId = v.toUpperCase())"
                        :state="isValidExp(site.companyId, 'num', 4)"
                        maxLength="4"
                        required
                        trim
                      />
                    </BFormGroup>

                    <BFormGroup
                      size="sm"
                      label="사이트 식별 코드"
                      label-for="siteId"
                      label-cols-md="3"
                      valid-feedback="사용 가능한 코드입니다"
                      invalid-feedback="16진수 4자 예) 1F30"
                      :state="isValidExp(site.siteId, 'hex', 4)"
                    >
                      <BFormInput
                        size="sm"
                        id="siteId"
                        v-model="site.siteId"
                        :state="isValidExp(site.siteId, 'hex', 4)"
                        @input="v => (site.siteId = v.toUpperCase())"
                        maxLength="4"
                        required
                        trim
                      />
                    </BFormGroup>

                    <BFormGroup
                      size="sm"
                      label="서버 IP 주소"
                      label-for="serverIp"
                      label-cols-md="3"
                      valid-feedback="IP 주소 형식입니다"
                      invalid-feedback="IP 주소  예) 192.168.10.123"
                      :state="isValidExp(site.serverIp, 'ip')"
                    >
                      <BFormInput
                        size="sm"
                        id="serverIp"
                        v-model="site.serverIp"
                        :state="isValidExp(site.serverIp, 'ip')"
                        maxLength="40"
                        required
                        trim
                      />
                    </BFormGroup>
                    <BFormGroup
                      size="sm"
                      label="Gateway IP"
                      label-for="gatewayIp"
                      label-cols-md="3"
                      valid-feedback="IP 주소 형식입니다"
                      invalid-feedback="G/W IP 주소  예) 192.168.10.1"
                      :state="isValidExp(site.gatewayIp, 'ip')"
                    >
                      <BFormInput
                        size="sm"
                        id="gatewayIp"
                        v-model="site.gatewayIp"
                        :state="isValidExp(site.gatewayIp, 'ip')"
                        maxLength="40"
                        required
                        trim
                      />
                    </BFormGroup>

                    <BFormGroup
                      v-if="false"
                      size="sm"
                      label="장비 암호 키"
                      label-for="siteKey"
                      label-cols-md="3"
                      valid-feedback="암호키 형식입니다"
                      invalid-feedback="16진수 16자  예) 0F0E109988773625"
                      :state="isValidExp(site.key, 'hex', 16)"
                    >
                      <BFormInput
                        size="sm"
                        id="siteKey"
                        v-model="site.key"
                        @input="v => (site.key = v.toUpperCase())"
                        :state="isValidExp(site.key, 'hex', 16)"
                        maxLength="16"
                        trim
                      />
                    </BFormGroup>

                    <BFormGroup
                      v-if="false"
                      size="sm"
                      label="장비 암호 벡터"
                      label-for="siteVector"
                      label-cols-md="3"
                      valid-feedback="벡터 형식입니다"
                      invalid-feedback="16진수 16자 예) 0F0E109988773625"
                      :state="isValidExp(site.vector, 'hex', 16)"
                    >
                      <BFormInput
                        size="sm"
                        id="siteVector"
                        v-model="site.vector"
                        @input="v => (site.vector = v.toUpperCase())"
                        :state="isValidExp(site.vector, 'hex', 16)"
                        maxLength="16"
                        trim
                      />
                    </BFormGroup>

                    <BFormGroup
                      size="sm"
                      label="전송 식별 코드"
                      label-for="senderCode"
                      label-cols-md="3"
                      valid-feedback="식별코드 형식입니다"
                      invalid-feedback="숫자 8자  예) 12345678"
                      :state="isValidExp(site.senderCode, 'num', 8)"
                    >
                      <BFormInput
                        size="sm"
                        id="senderCode"
                        v-model="site.senderCode"
                        :state="isValidExp(site.senderCode, 'num', 8)"
                        maxLength="20"
                        required
                        trim
                      />
                    </BFormGroup>
                  </CCardBody>
                </CCard>

                <CCard accent-color="info">
                  <CCardHeader> 책임자 정보 </CCardHeader>
                  <CCardBody>
                    <CSelect
                      size="sm"
                      id="managerId"
                      horizontal
                      label="책임자 계정"
                      :value.sync="site.managerId"
                      :options="accountOpts"
                      :state="site.managerId"
                      required
                    />

                    <BFormGroup
                      size="sm"
                      label="책임자 사번"
                      label-for="managerNo"
                      label-cols-md="3"
                    >
                      <BFormInput
                        size="sm"
                        id="managerNo"
                        v-model="site.managerNo"
                        maxLength="50"
                        trim
                      />
                    </BFormGroup>

                    <BFormGroup
                      size="sm"
                      label="책임자 성명"
                      label-for="managerName"
                      label-cols-md="3"
                    >
                      <BFormInput
                        size="sm"
                        id="managerName"
                        v-model="site.managerName"
                        maxLength="50"
                        trim
                      />
                    </BFormGroup>

                    <BFormGroup
                      size="sm"
                      label="책임자 직급"
                      label-cols-md="3"
                      label-for="managerRank"
                    >
                      <BFormInput
                        size="sm"
                        id="managerRank"
                        v-model="site.managerRank"
                        maxLength="50"
                        trim
                      />
                    </BFormGroup>
                  </CCardBody>
                </CCard>

                <BRow>
                  <BCol></BCol>
                  <BCol>
                    <BButton
                      block
                      type="submit"
                      variant="primary"
                      :disabled="site.siteId === '' || isSiteSaving === true"
                    >
                      <div
                        class="sk-plane bg-light float-left"
                        style="height: 25px; width: 25px"
                        v-if="isSiteSaving"
                      ></div>
                      저 장
                    </BButton>
                  </BCol>
                </BRow>
              </BForm>
            </CCardBody>
          </CCard>
        </BCol>

        <BCol xl="6">
          <CCard border-color="info" class="min-vh-100">
            <CCardHeader>
              <BIconGrid1x2Fill class="mr-2" /> <strong> 관제지역 정보 </strong>
            </CCardHeader>

            <CCardBody>
              <BRow class="mb-2">
                <BCol>
                  <BInputGroup>
                    <BButton variant="primary" class="mr-3" @click="createArea">
                      <BIconPatchPlusFill />
                      관제지역 생성
                    </BButton>
                  </BInputGroup>
                </BCol>
              </BRow>

              <BRow>
                <BCol>
                  <vue-excel-editor v-model="rows"
                    ref="excelGrid"
                    width="100%"
                    class="mb-1"
                    :page="numOfRecord"
                    :readonly-style="{ backgroundColor: '#4F5040' }"
                    @update="updateCell"
                    @select="selectRecord"
                    :localized-label="editorLabel"
                    filter-row
                  >
                    <vue-excel-column field="_id" type="string" key-field invisible/>
                    <vue-excel-column field="name" type="string" width="100px" label="지역명" sticky/>
                    <vue-excel-column field="description" type="string" width="150px" label="상세정보"/>
<!--                    <vue-excel-column
                      field="managerId"
                      type="map"
                      width="100px"
                      label="관리자"
                      :options="accountMap"
                    />-->
                    <vue-excel-column field="updatedAt" type="date" width="110px" label="수정일" :to-text="toLocalTime" readonly/>
                    <vue-excel-column field="updId" type="string" width="100px" label="수정자" readonly/>
                    <vue-excel-column field="createdAt" type="date" width="110px" label="등록일" :to-text="toLocalTime" readonly/>
                    <vue-excel-column field="regId" type="string" width="100px" label="등록자" readonly/>
                  </vue-excel-editor>
                </BCol>
              </BRow>
              <BRow class="mb-3">
                <BCol lg="8">
                  <BButtonGroup>
                    <BButton variant="primary" @click="applyGroup">
                      <BIconCheckCircleFill /> 설정 반영
                    </BButton>

                    <BButton
                      variant="danger"
                      class="ml-2"
                      @click="deleteRecord"
                      :disabled="selectedGroup === null || isGroupDeleting"
                    >
                      <BIconTrashFill /> 선택 삭제
                    </BButton>
                  </BButtonGroup>
                </BCol>
                <BCol>
                  <BProgress
                    height="2rem"
                    v-show="progCount > 0"
                    :value="progCount"
                    :max="totalCount"
                    variant="warning"
                    show-value
                  />
                </BCol>
              </BRow>

              <CCard v-if="selectedGroup !== null" accent-color="info">
                <CCardHeader>
                  <BBadge variant="secondary">{{ selectedGroup.arCode }}</BBadge>
                  <strong> {{ selectedGroup.name }} </strong>
                </CCardHeader>

                <CTabs>
                  <CTab title="지역관리자" active>
                    <BRow>
                      <BCol>
                        <BFormSelect
                          v-model="selectedUser"
                          :options="filteredSSListOptions"
                          class="dark-mode-select"
                        />
                      </BCol>
                      <BCol>
                        <BButton @click="addUserToArea">추가</BButton>
                        <BButton
                          @click="removeUserFromArea"
                        >
                          삭제
                        </BButton>
                      </BCol>
                    </BRow>
                    <BTable
                      :items="selectedGroupManagers"
                      :fields="groupManagerFields"
                      selectable
                      @row-selected="onRowSelected"
                    />
                  </CTab>
                  <CTab title="탱크정보" >
                    <tank-list class="mt-2" :ar-code="selectedGroup.arCode" simple />
                  </CTab>

                </CTabs>
              </CCard>
            </CCardBody>
          </CCard>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<style src="spinkit/spinkit.min.css"></style> <!--animated processing icon-->

<script>
import "@/common/HelperMixin";
import {
  apiCall,
  cloneVar,
  setAreas,
} from "@/common/utils";
import { ExcelEditorLabel } from "@/common/constants";
import TankList from "../components/TankList";

const _site = {
  siteId: "",
  companyId: "",
  name: "",
  address: "",
  tel1: "",
  tel2: "",
  onlineYn: "",
  department: "",
  senderCode: "",
  managerId: "",
  managerNo: "",
  managerRank: "",
  managerName: "",
  serverIp: "",
  gatewayIp: "",
  key: "",
  vector: "",
};

const _group = {
  arCode: "",
  name: "신규지역",
  description: "",
  managerId: "smartpol",
};

const groupColumnMap = {
  arCode: "지역 코드",
  name: "지역명",
  description: "지역 상세정보",
  managerId: "지역 책임자",
};

export default {
  name: "Site",
  components: {
    TankList,
  },
  data() {
    return {
      isSiteSaving: false,
      isGroupDeleting: false,
      maps: this.$store.state.codeMaps,
      site: cloneVar(_site),
      accountOpts: [
        { value: "", label: "계정 선택" },
        { value: "smartpol", label: "[smartpol] 시스템 관리자" },
      ],
      accountMap: {
        smartpol: "시스템관리자",
      },
      editorLabel: ExcelEditorLabel,
      selectedGroup: null,
      arCodeMap: {},
      arCodeOpts: [],
      downloadReason: "",
      searchWord: "",
      progCount: 0,
      totalCount: 0,
      rows: [],
      paramMap: {},
      numOfRecord: 10,
      siteFields: {},
      modalTitle: "",
      tankFormShow: false,
      SSList: [],
      selectedUser: "",
      selectedGroupManagers: [],
      groupManagerFields: [
        { key: "userId", label: "사용자 ID" },
        { key: "name", label: "이름" },
        { key: "grade", label: "직급" },
        { key: "position", label: "직책" },
      ],
    };
  },
  async created(){
    try{
      // await this.setMachineMap();
      await this.getAccounts();

      await this.getSSUsers();

      await this.getSiteInfo();

      await this.getAreaList();

    }catch(err){
      console.log(err);
    }
  },

  mounted() {
    console.log("--- site mounted---------------------");
    // below is not work!
  },
  computed: {
    SSListOptionsLeft() {
      if (this.selectedGroup) {
        return this.SSList.filter(
        (user) => !user.areas.includes(this.selectedGroup._id)
      ).map((user) => ({
        value: user._id,
        text: `${user.name} (${user.userId})`,
      }));
      }
      return [];
    },
    SSListOptionsRight() {
      if (this.selectedGroup) {
        return this.SSList.filter((user) =>
          user.areas.some(area => area._id === this.selectedGroup._id)
        ).map((user) => ({
          value: user._id,
          text: `${user.name} (${user.userId})`,
        }));
      }
      return [];
    },
    filteredSSListOptions() {
      const selectedManagerIds = this.selectedGroupManagers.map(manager => manager.userId);
      return this.SSList.filter(user => !selectedManagerIds.includes(user.userId))
        .map(user => ({
          value: user._id,
          text: `${user.name} (${user.userId})`,
        }));
    },
  },
  methods: {
    async selectRecord(idx, evt){
      if(!evt){
        this.selectedGroup = null;
        return;
      }
      this.selectedGroup = this.$refs['excelGrid'].getSelectedRecords().pop();
      if (this.selectedGroup) {
        await this.getGroupManagers(this.selectedGroup.arCode);
      }
    },
    async getGroupManagers(groupId) {
      try {
        const r = await apiCall("get", `/api/area/${groupId}/managers`);
        if (r.code === 200) {
          this.selectedGroupManagers = r.result;
        } else {
          await this.alertDanger(r.message, r.code);
        }
      } catch (err) {
        await this.alertDanger(err.message);
      }
    },
    async siteSubmit(evt){
      console.log('------------- saveSubmit --------------- evt --->', evt );
      evt.preventDefault();

      // 유효성 검사
      const invalidFields = [];
      if (this.site.name.length <= 2) invalidFields.push('사이트 이름');
      if (!this.isValidExp(this.site.telNo, 'tel') && !this.isValidExp(this.site.telNo, 'hp')) invalidFields.push('전화번호');
      if (!this.isValidExp(this.site.faxNo, 'tel')) invalidFields.push('팩스번호');
      if (!this.isValidExp(this.site.companyId, 'num', 4)) invalidFields.push('서비스 식별 코드');
      if (!this.isValidExp(this.site.siteId, 'hex', 4)) invalidFields.push('사이트 식별 코드');
      if (!this.isValidExp(this.site.serverIp, 'ip')) invalidFields.push('서버 IP 주소');
      if (!this.isValidExp(this.site.gatewayIp, 'ip')) invalidFields.push('Gateway IP');
      if (!this.isValidExp(this.site.senderCode, 'num', 8)) invalidFields.push('전송 식별 코드');

      if (invalidFields.length > 0) {
        await this.alertSuccess(`다음 필드가 유효하지 않습니다: ${invalidFields.join(', ')}`, '저장 실패');
        return;
      }
      let r;
      try{
        this.isSiteSaving = true;
        if(this.site._id) {
          // 정보 수정
          r = await apiCall("PUT", `/api/station/${this.site._id}`, this.site);
        }else{
          // 정보 등록
          r = await apiCall("POST", `/api/station`, this.site);
        }
        console.log( r );
        this.site = r.result;

        if(r.code===200){
          await this.alertSuccess('사이트 정보가 저장 되었습니다.', '사이트 정보');
        }else{
          await this.toastResult( r, '사이트 정보 저장')
        }

      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.log( err );
      }finally{
        this.isSiteSaving = false;
      }

    },
    async getSSUsers() {
      try {
        const r = await apiCall("get", `/api/area/user/SS`);
        if (r.code === 200) {
          this.SSList = r.result;
          console.log('사용자', this.SSList)
        } else {
          await this.alertDanger(r.message, r.code);
        }
      } catch (err) {
        await this.alertDanger(err.message);
      }
    },
    // get SSListOptions() {
    //   return this.SSList.map((user) => ({
    //     value: user._id,
    //     text: `${user.name} (${user.userId})`,
    //   }));
    // },
    async addUserToArea() {
      if (!this.selectedUser || !this.selectedGroup) {
        await this.alertSuccess(`선택된 대상이 없습니다.`);
        return;
      }
      try {
        const r = await apiCall("put", `/api/area/user`, {
          userId: this.selectedUser,
          arCode: this.selectedGroup.arCode,
        });
        this.selectedUser = '';
        this.selectedGroupManager = [];
        if (r.code === 200) {
          await this.getGroupManagers(this.selectedGroup.arCode);
          await this.alertSuccess('사용자가 영역에 성공적으로 추가되었습니다.', '사이트 정보');

          const userIndex = this.SSList.findIndex(user => user._id === this.selectedUser);
          if (userIndex !== -1) {
            this.SSList[userIndex].areas.push(this.selectedGroup._id);
          }
        } else {
          await this.alertDanger(r.message, r.code);
        }
      } catch (err) {
        await this.alertDanger(err.message);
      }
    },
    async removeUserFromArea() {
      if (!this.selectedGroupManager || this.selectedGroupManager?.length === 0)  {
        await this.alertSuccess(`선택된 대상이 없습니다.`);
        return;
      }
      try {
        const r = await apiCall("PUT", `/api/area/user/modify`, {
          userId: this.selectedGroupManager.userId,
          areaId: this.selectedGroup.arCode,
        });
        this.selectedUser = '';
        this.selectedGroupManager = [];
        if (r.code === 200) {
          await this.getGroupManagers(this.selectedGroup.arCode);
          await this.alertSuccess('사용자가 영역에서 성공적으로 제거되었습니다.', '사이트 정보');

          const userIndex = this.SSList.findIndex(user => user.userId === this.selectedGroupManager?.[0]?.userId);
          if (userIndex !== -1) {
            const areaIndex = this.SSList[userIndex].areas.indexOf(this.selectedGroup.arCode);
            if (areaIndex !== -1) {
              this.SSList[userIndex].areas.splice(areaIndex, 1);
            }
          }
          
        } else {
          await this.alertDanger(r.message, r.code);
        }
      } catch (err) {
        await this.alertDanger(err.message);
      }
    },
    async getSiteInfo(){
      try{
        const r = await apiCall('get', `/api/station`);

        if(r.code===200){
          if(!r.result.length){
            await this.alertWarn('사이트 정보가 없습니다.', '사이트 정보 등록');
            this.site = cloneVar(_site);

          } else {
            this.site = r.result.pop();

          }
        }else{
          await this.alertDanger(r.message, r.code);
        }

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }
    },
    async getAccounts(){
      try{
        const rs = await apiCall('get', `/api/user`);

        if(rs.code===200){
          rs.result.map(r=>{
            this.accountMap[r.userId] = `[${r.userId}] ${r.name}`;
          });
        }

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }
    },
    async createArea(){
      console.log('------------- createArea ---------------');
      let r;
      try{
        r = await apiCall("POST", `/api/area`, _group);
        console.log( r );
        if(r.code===200){
          await this.getAreaList();
        }
        await this.toastResult(r, `관제지역 등록`);
      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.log( err );
      }
    },

    async applyGroup(){
      try{
        if( await setAreas() ){
          await this.alertSuccess('관제지역 정보가 적용 되었습니다.');
          await this.getAreaList();
        }
      }catch(err){
        console.error(err);
      }
    },

    async getAreaList(){
      try{
        this.rows = [];
        const r = await apiCall('get', `/api/area`);
        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`${this.rows.length}건 조회됨`, 'info');
        }
      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }
    },

    async updateCell(rec){
      let rc = rec.pop();
      if(!rc.keys[0]){
        alert( "KEY_NOT_FOUND" );
        return;
      }

      console.log( "updateRecord--------- record.$id--->",rc.$id );
      console.log( "updateRecord--------- record.keys[0]--->",rc.keys[0] );

      let r = null;

      let param = {};
      const objectId = rc.keys[0];
      const fieldName = rc.name;
      param[fieldName] = rc.newVal;
      try{
        console.log( 'param --->', param);
        r = await apiCall("PUT", `/api/area/${objectId}`, param);
        console.log( r );
        await this.toastResult(r, `${groupColumnMap[fieldName]} 수정`);
      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.log( err );
      }
    },

    async deleteRecord(recs){
      this.isGroupDeleting = true;
      try{
        let params = this.$refs['excelGrid'].getSelectedRecords();
        this.progCount = 0;
        this.totalCount = params.length;
        if(!params.length) return this.toastInfo('[선텍된 레코드 없음] 좌측의 번호를 클릭하여 레코드 선택');

        console.log( "deleteRecord --- recs ---", recs.length );
        console.log( "deleteRecord --- params ", params );
        console.log( "deleteRecord length ----> ", params.length );

        const confirmMsg = `${params.length} 개의 데이터를 삭제 합니다. 삭제된 데이터는 복구할 수 없으며 시스템 장애가 발생할 수 있습니다. 진행 하시겠습니까?`;

        if( !(await this.confirmModal(confirmMsg, '레코드 삭제')) ){
          return;
        }

        let failCnt = 0;
        for( let record of params){
          console.log( "deleteRecord for --------->", record );
          const tIndex = this.$refs['excelGrid'].rowIndex[record.$id];
          const r = await apiCall('DEL', `/api/area/${record._id}`);
          console.log( r );
          if( r.code===200 ){
            this.progCount++;
            this.$refs["excelGrid"].deleteRecord(tIndex);
          }else{
            failCnt++;
            this.toastInfo('삭제 에러: '+r.message, 'Error', 'danger' );
          }
        }

        await this.alertModal(`삭제: ${this.progCount}건, 실패: ${failCnt}`, '레코드 삭제결과', 'warning');
        this.$refs['excelGrid'].clearAllSelected();
        await this.getAreaList();

      }catch(err){
        console.log( err );
      }finally{
        this.progCount = 0;
        this.isGroupDeleting = false;
        this.selectedGroup = null;
      }
    },
    onRowSelected(items) {
        if (items.length > 0) {
          this.selectedGroupManager = items[0];
        } else {
          this.selectedGroupManager = null;
        }
        console.log('Selected Group Manager:', this.selectedGroupManager);
    },
  }
}
</script>

<style scoped>
.dark-mode-select {
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
}

.dark-mode-select .dropdown-item {
  background-color: #333;
  color: #fff;
}

.dark-mode-select .dropdown-item:hover {
  background-color: #444;
}

.dark-mode-select .dropdown-menu {
  background-color: #333;
  border: 1px solid #555;
}

.dark-mode-select option {
  background-color: #333;
  color: #fff;
}

.vue-excel-editor .table-content .systable .center-text .table-col-header {
  pointer-events: none;
}
</style>
