<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
    <!-- 탱크리스트 --------------------------------------------------------------------------------------------->

    <CDataTable
      size="sm"
      :items="tanks"
      :fields="tankFields"
      items-per-page-select
      :items-per-page="5"
      hover
      sorter
      pagination
      :columnFilter="!simple"
      :tableFilter="!simple"
      cleaner
    >

      <template #color="{item}">
        <td class="align-middle">
          <BButton block :style="{backgroundColor: item.color}" size="sm">{{ item.tid }}</BButton>
        </td>
      </template>

      <template #oilCode="{item}">
        <td class="align-middle">
          <BBadge class="w-75">{{oilCodeMap[item.oilCode]}}</BBadge>
        </td>
      </template>

    </CDataTable>


  </div>
</template>



<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
} from '@/common/utils';
import qs from 'querystring';


// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };


//----------------------------------------------------------------------------------------------------
export default {
  name: "TankList",
  components: {
  },
  props: {
    arCode: { type: String, default: null },
    oilCode: { type: String, default: null },
    tankType: { type: String, default: null },
    simple: { type: Boolean, default: false },
  },
  data () {
    return {
      oilCodeMap: this.$store.state.codeMaps['OIL_CODE'],
      tankTypeMap: this.$store.state.codeMaps['TANK_TYPE'],
      tanks: [],
      tankFields: this.getFields(),
      selectedTank: null,
      selectedMachineId: null,
      tank : null,
      machine: null,

      downloadModalShow: false,
      arCodeMap  : {},
      arCodeOpts  : [],
      downloadReason: '',

      searchField: 'tid',
      searchWord : '',
      progCount: 0,
      totalCount: 0,
      rows: [],


      paramMap: {},
      numOfRecord: 10,

      modalTitle: '',
      tankFormShow: false,

    }

  },
  async created(){
    try{
      await this.getTankList();
    }catch(err){
      console.log(err);
    }
  },

  computed: {},

  mounted() {
    console.log("--- TankList mounted---------------------");
    // below is not work!
  },

  methods: {
    async getTankList(){
      try{
        let qry = '';
        let paramMap = {};
        if(this.arCode) paramMap['arCode'] = this.arCode;
        if(this.oilCode ) paramMap['oilCode'] = this.oilCode;
        if(this.tankType ) paramMap['tankType'] = this.tankType;

        qry = qs.stringify( paramMap );
        console.log( "getTankList() --- query-string ----> ", qry );

        this.tanks = [];
        const r = await apiCall('get', `/api/tank?${qry}`);

        if(r.code===200){
          this.tanks = r.result;
          await this.toastInfo(`탱크정보 ${this.tanks.length}건 조회됨`, 'info');
        }
        this.selectedTank = null;

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }

    },

    getFields(){
      if(!this.simple) {
      return [
          { key: 'tid', label: '탱크ID', _style: 'min-width:5%' },
          { key: 'name', label: '탱크명', _style: 'min-width:5%' },
          { key: 'arCode', label: '지역코드', _style: 'min-width:5%' },
          { key: 'oilCode', label: '저장유종', _style: 'min-width:10%' },
          { key: 'tankType', label: '탱크종류', _style: 'min-width:10%' },
          { key: 'tankUse', label: '사용형태', _style: 'min-width:10%' },
          { key: 'color', label: 'color', _style: 'min-width:10%' },
          { key: 'tankVolume', label: '저장용량', _style: 'min-width:10%' },
          { key: 'mid', label: '설치장비', _style: 'min-width:10%' },
        ];
      }else{
        return [
          { key: 'tid', label: '탱크ID', _style: 'min-width:5%' },
          { key: 'name', label: '탱크명', _style: 'min-width:5%' },
          { key: 'tankType', label: '탱크종류', _style: 'min-width:10%' },
          { key: 'arCode', label: '지역코드', _style: 'min-width:5%' },
          { key: 'oilCode', label: '저장유종', _style: 'min-width:10%' },
          { key: 'color', label: 'color', _style: 'min-width:10%' },
        ];
      }
    },




  }
}
</script>
